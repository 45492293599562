import { addDays, addMinutes, format } from "date-fns";
import React, { useState } from "react";

import Loading from "../../../components/Loading";
import Text from "../../../components/Text";

import colors from "../../../shared/utils/constants/colors";
import { launchToast } from "../../../shared/utils/launchToast";
import processError from "../../../shared/utils/processError";

import ExcelJS from "exceljs";
import PowerSupplyEnum from "../../../shared/enums/PowerSupplyEnum";
import calculateDistanceBetweenHours from "../../../shared/utils/calculateDistanceBetweenHours";
import { calculateSizeByWeigthPet } from "../../../shared/utils/calculateSizeByWeigthPet";
import convertToCurrencyFormat from "../../../shared/utils/convertToCurrencyFormat";
import AppointmentsReportTable from "./AppointmentsFinancialReportTable";
import AppointmentsResumePrices from "./AppointmentsFinancialResumePrices";
import * as Styles from "./styles";

import { useDisclosure } from "@chakra-ui/hooks";
import { useAuth } from "../../../hooks/Auth";
import AppointmentStatusEnum from "../../../shared/enums/AppointmentStatusEnum";
import PlatformEnum from "../../../shared/enums/PlatformEnum";
import { IAppointmentFiltersFormData } from "./forms/AppointmentFinancialFiltersForm/types";
import { IAppointmentsData, loadAppointmentsByRange, loadReportResumeAppointmentsFinantial, loadReportResumePrices } from "./graphQL";
import AppointmentFiltersModal from "./modals/AppointmentFinancialFiltersModal";
import { IAppointmentReport, ITotalResumePricesProps } from "./types";
import Pagination from "components/Pagination";
import { calcServiceValue, calculateAppointmentPaidPrice } from "shared/utils/normalizeAppointments";

const formatEndTime = (time: any, executionTimeInMinutes: any) => {
  if (!time || !executionTimeInMinutes || isNaN(executionTimeInMinutes)) {
    return 'no record';
  }

  const [hoursStr, minutesStr] = time.split(":");
  const hours: number = parseInt(hoursStr);
  const minutes: number = parseInt(minutesStr);

  const correctedMinutes: string = minutes.toString().padStart(2, "0");
  const correctedHours: string = hours.toString().padStart(2, '0');

  const appointment = new Date(`1970-01-01T${correctedHours}:${correctedMinutes}`);
  const result = addMinutes(appointment, executionTimeInMinutes);

  return `${result.getHours()}:${result.getMinutes()}`;
};

enum TransactionTaypeEnum {
  definir = 0,
  cartão = 1,
  link = 2,
  pix = 3,
  transferencia = 4,
  dinheiro = 5,
  newLink = 6,
  linkavulso = 7,
  reembolso = 8,
};

const AppointmentsReport: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [selectedTabOption, setSelectedTabOption] = useState<
    "table" | "totals"
  >("table");
  const [appointmentsFormatted, setAppointmentsFormatted] = useState<
    IAppointmentReport[] | [] | null
  >(null);
  const [selectedFilters, setSelectedFilters] =
    useState<IAppointmentFiltersFormData | null>(null);
  const [totalResumePrices, setTotalResumePrices] = useState<
    ITotalResumePricesProps | undefined
  >(undefined);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);

  const [appointmentsSinglePage, setAppointmentsSinglePage] = useState<IAppointmentsData[]>([])
  const [exportingExcel, setExportingExcel] = useState(false);


  const handleSearchAppointmentsByDateRange = async ({
    start_date,
    end_date,
    categories,
    status,
    tutor_name
  }: IAppointmentFiltersFormData) => {
    try {
      setPage(1);
      setLoading(true);


      const allAppointments = [];
      let page = 1;
      let totalPages = 1;

      while (page <= totalPages) {
        const { appointments, totalPages: fetchedTotalPages } = await loadAppointmentsByRange({
          filters: { start_date, end_date, categories, status, tutor_name },
          page,
        });

        totalPages = fetchedTotalPages;
        allAppointments.push(...appointments);
        page += 1;
      }

      // Fetch financial summary report
      const { appointmentsFinantialResumePricesReports } = await loadReportResumeAppointmentsFinantial({
        filters: { start_date, end_date, categories, status, tutor_name },
      });

      setNumberOfPages(totalPages);
      setAppointmentsSinglePage(appointmentsFinantialResumePricesReports.appointmentsData);
      setSelectedFilters({ start_date, end_date, categories, status, tutor_name });
      setTotalResumePrices(appointmentsFinantialResumePricesReports.finantialReport);

      const formattedAppointments = allAppointments.map((appointment) => {
        const serviceTotalPrice = Number(appointment.service_price / 100);
        const additionalsTotalPrice = appointment.appointments_items.reduce(
          (total, additional) => total + Number(additional.item_price),
          0
        );
        const appointmentTotalPrice = calcServiceValue(appointment);
        const appointmentPaidPrice = calculateAppointmentPaidPrice(
          appointment,
          appointmentTotalPrice
        );
        let payment = "";
        if (appointment.status >= AppointmentStatusEnum.Pago) {
          payment = "CONCLUIDO";
        } else if (appointment.status === AppointmentStatusEnum["Aguardando pagamento"]) {
          payment = "AGUARDANDO";
        } else if (appointment.status === AppointmentStatusEnum["Aguardando Pagamento Adicional"]) {
          payment = "AGUARDANDO PAGAMENTO ADICIONAL";
        }

        let platform = "sem registro";
        switch (appointment.order.platform) {
          case PlatformEnum.mobile:
            platform = "cliente";
            break;
          case PlatformEnum.admin:
            platform = "admin";
            break;
          case PlatformEnum.job:
            platform = "automatico";
            break;
          case PlatformEnum.landpage:
            platform = "landpage";
            break;
          case PlatformEnum.auto_renew:
            platform = "renovacao automática";
            break;
        }

        return {
          period: appointment.period.toString() || "sem registro",
          observations: appointment.observations || "",
          deslocation_time: !!appointment.on_the_way_status_hour && !!appointment.on_local_status_hour
            ? calculateDistanceBetweenHours(appointment.on_the_way_status_hour, appointment.on_local_status_hour).toString()
            : "",
          difference_time: appointment.on_the_way_status_hour && appointment.service_completed_status_hour
            ? calculateDistanceBetweenHours(appointment.on_the_way_status_hour, appointment.service_completed_status_hour).toString()
            : "sem registro",
          start_time: appointment.hour || "sem registro",
          end_time: formatEndTime(appointment.hour, appointment.execution_time),
          real_start_time: appointment.on_the_way_status_hour || "",
          real_end_time: appointment.service_completed_status_hour || "",
          customer_name: appointment.customer.user.name || "sem registro",
          customer_cpf: appointment.customer.user.cpf || "sem registro",
          pet_name: appointment.pet.name || "sem registro",
          pet_breed: appointment.pet.breed?.name || "sem registro",
          pet_gender: appointment.pet.gender || "sem registro",
          pet_specie: appointment.pet.specie.name || "sem registro",
          pet_size: appointment.pet?.breed?.size
            ? appointment.pet?.breed?.size
            : calculateSizeByWeigthPet(appointment.pet.specie.name, appointment.pet.weight || 0) || "sem registro",
          cep: appointment.address.cep,
          address_street: appointment.address.street || "sem registro",
          address_neighborhood: appointment.address.neighborhood || "sem registro",
          address_number: appointment.address.number || "sem registro",
          service_name: appointment.service.name || "sem registro",
          service_full_price: convertToCurrencyFormat(serviceTotalPrice),
          additionals: appointment.appointments_items.map((additional) => additional.item.name).toString() || "sem registro",
          additionals_full_price: convertToCurrencyFormat(additionalsTotalPrice.toFixed(2)),
          discount: convertToCurrencyFormat(appointmentTotalPrice - Number(appointmentPaidPrice)),
          coupon_relation: appointment.order.coupon_relation?.code || "Sem cupom",
          paided_price: convertToCurrencyFormat(appointmentPaidPrice),
          payment: payment,
          observations_admin: "",
          power_supply: appointment.power_supply ? PowerSupplyEnum[appointment.power_supply] : "sem registro",
          professional: {
            id: appointment.professional?.id,
            name: appointment.professional?.user.name,
          },
          platform: platform,
        };
      });

      setAppointmentsFormatted(formattedAppointments);
      setLoading(false);

    } catch (error) {
      const { message } = processError(error, "GRAPHQL");
      setLoading(false);
      launchToast(message, "error");
    }
  };

  const handleExportFullData = async () => {
    if (!selectedFilters?.start_date || !selectedFilters?.end_date) {
      return;
    }

    setExportingExcel(true);

    try {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = user.name;
      workbook.lastModifiedBy = user.name;
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();
      workbook.views = [
        {
          x: 0, y: 0, width: 10000, height: 20000, firstSheet: 0, activeTab: 1, visibility: "visible",
        },
      ];

      const sheet = workbook.addWorksheet("Appointments Report");

      const headers = [
        "DATAAGENDAMENTO", "DATASERVIÇO", "PERÍODO", "OBS.", "DESL.", "TEMPO",
        "INÍCIO", "FIM", "INÍCIO REAL", "FIM REAL", "TUTOR", "CPF", "PET", "RAÇA",
        "SEXO", "ESPÉCIE", "PORTE", "CEP", "ENDEREÇO", "BAIRRO", "NUMERO", "COMPLEMENT",
        "SERVIÇO", "PACOTE", "ADICIONAIS", "VALOR DO SERVICO", "VALOR DO ADICIONAL",
        "DESCONTO", "CUPOM", "VALOR FINAL", "PAGAMENTO", "OBSERVAÇÕES", "GERADOR - TOMADA",
        "CRIADO PELO", "GERADO EM", "VALOR PEDIDO", "TIPO DE TRANSAÇÃO", "ID DA TRANSAÇÃO",
        "PAGARME IDS", "PROVEDOR", "PROFISSIONAL"
      ];

      sheet.addRow(headers).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "8EB7AA" },
      };

      const { start_date, end_date, categories, status, tutor_name } = selectedFilters

      const { appointments } = await loadAppointmentsByRange({
        filters: { start_date, end_date, categories, status, tutor_name },
      });

      appointments.forEach((appointment: any) => {

        const serviceTotalPrice = Number(appointment.service_price / 100);
        const additionalsTotalPrice = appointment.appointments_items.reduce(
          (total: any, additional: any) => total + Number(additional.item_price),
          0
        );
        const appointmentTotalPrice = calcServiceValue(appointment);
        const appointmentPaidPrice = calculateAppointmentPaidPrice(
          appointment,
          appointmentTotalPrice
        );
        let payment = "";
        if (appointment.status >= AppointmentStatusEnum.Pago) {
          payment = "CONCLUIDO";
        } else if (appointment.status === AppointmentStatusEnum["Aguardando pagamento"]) {
          payment = "AGUARDANDO";
        } else if (appointment.status === AppointmentStatusEnum["Aguardando Pagamento Adicional"]) {
          payment = "AGUARDANDO PAGAMENTO ADICIONAL";
        }

        const providers = appointment.order?.transactions[0]?.provider || 'sem registro'
        const transactionIds = appointment.order?.transactions[0]?.id || 'sem registro'
        const transactionExternalIds = appointment.order?.transactions[0]?.transaction_pagseguro_id || 'sem registro'
        const transactionType = TransactionTaypeEnum[appointment.order[0]?.transactions.type] || 'sem registro'

        let platform = "sem registro";
        switch (appointment.order.platform) {
          case PlatformEnum.mobile:
            platform = "cliente";
            break;
          case PlatformEnum.admin:
            platform = "admin";
            break;
          case PlatformEnum.job:
            platform = "automatico";
            break;
          case PlatformEnum.landpage:
            platform = "landpage";
            break;
          case PlatformEnum.auto_renew:
            platform = "renovacao automática";
            break;
        }

        const row = [
          format(new Date(appointment.created_at), "yyyy-MM-dd") || "sem registro",
          appointment.date.toString() || "sem registro",
          appointment.period.toString() || "sem registro",
          appointment.observations || "",
          !!appointment.on_the_way_status_hour && !!appointment.on_local_status_hour
            ? calculateDistanceBetweenHours(
              appointment.on_the_way_status_hour,
              appointment.on_local_status_hour
            ).toString()
            : "",
          appointment.on_the_way_status_hour && appointment.service_completed_status_hour
            ? calculateDistanceBetweenHours(
              appointment.on_the_way_status_hour,
              appointment.service_completed_status_hour
            ).toString()
            : "",
          appointment.hour || "sem registro",
          appointment.service_completed_status_hour || "sem registro",
          appointment.on_the_way_status_hour || "",
          appointment.service_completed_status_hour || "",
          appointment.customer?.user?.name || "sem registro",
          appointment.customer?.user?.cpf || "sem registro",
          appointment.pet?.name || "sem registro",
          appointment.pet?.breed?.name || "sem registro",
          appointment.pet?.gender || "sem registro",
          appointment.pet?.specie?.name || "sem registro",
          appointment.pet?.breed?.size
            ? appointment.breed_size
            : calculateSizeByWeigthPet(appointment.pet?.specie?.name, appointment.pet.weight || 0) ||
            "sem registro",
          appointment.address.cep,
          appointment.address.street || "sem registro",
          appointment.address.neighborhood || "sem registro",
          appointment.address.number || "sem registro",
          appointment.complement || "sem registro",
          appointment?.service?.name || "sem registro",
          appointment?.pack?.frequency?.toLowerCase() || "sem registro",
          appointment.appointments_items?.map((additional: any) => additional.item?.name).toString() || "sem registro",
          convertToCurrencyFormat(serviceTotalPrice),
          convertToCurrencyFormat(additionalsTotalPrice.toFixed(2)),
          convertToCurrencyFormat(appointmentTotalPrice - Number(appointmentPaidPrice)),
          appointment.order.coupon_relation?.code || "Sem cupom",
          convertToCurrencyFormat(appointmentPaidPrice),
          payment,
          "",
          platform,
          appointment.power_supply
            ? PowerSupplyEnum[appointment.power_supply]
            : "sem registro",
          String(appointment.created_at),
          convertToCurrencyFormat(appointmentPaidPrice),
          transactionType,
          transactionIds,
          transactionExternalIds,
          providers,
          appointment.professional_name,
        ];

        sheet.addRow(row);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.ms-excel;charset=utf-8;",
        });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Appointments_Report_${selectedFilters.start_date}_to_${selectedFilters.end_date}.xlsx`;
        link.click();
      });
    } catch (error) {
      const { message } = processError(error, "GRAPHQL");
      launchToast(message, "error");
    } finally {
      setExportingExcel(false);
    }
  };

  const onPageChange = async (new_page: number) => {
    if (!selectedFilters?.start_date || !selectedFilters?.end_date) {
      return;
    }
    setPage(new_page);

    setLoading(true);

    try {
      const results = await loadAppointmentsByRange({
        filters: selectedFilters,
        page: new_page,
      });
      setAppointmentsFormatted(
        results.appointments.map((appointment) => {
          const serviceTotalPrice = Number(appointment.service_price / 100);
          const additionalsTotalPrice = appointment.appointments_items.reduce(
            (total, additional) => total + Number(additional.item_price),
            0
          );
          const appointmentTotalPrice = calcServiceValue(appointment);

          const appointmentPaidPrice = calculateAppointmentPaidPrice(
            appointment,
            appointmentTotalPrice
          );

          let payment = "";

          if (appointment.status >= AppointmentStatusEnum.Pago) {
            payment = "CONCLUIDO";
          } else if (
            appointment.status === AppointmentStatusEnum["Aguardando pagamento"]
          ) {
            payment = "AGUARDANDO";
          } else if (
            appointment.status ===
            AppointmentStatusEnum["Aguardando Pagamento Adicional"]
          ) {
            payment = "AGUARDANDO PAGAMENTO ADICIONAL";
          }

          let platform = "sem registro";

          if (appointment.order.platform === PlatformEnum.mobile) {
            platform = "cliente";
          } else if (appointment.order.platform === PlatformEnum.admin) {
            platform = "admin";
          } else if (appointment.order.platform === PlatformEnum.job) {
            platform = "automatico";
          } else if (appointment.order.platform === PlatformEnum.landpage) {
            platform = "landpage";
          }

          return {
            period: appointment.period.toString() || "sem registro",
            observations: appointment.observations || "",
            deslocation_time:
              !!appointment.on_the_way_status_hour &&
                !!appointment.on_local_status_hour
                ? calculateDistanceBetweenHours(
                  appointment.on_the_way_status_hour,
                  appointment.on_local_status_hour
                ).toString()
                : "",
            difference_time:
              appointment.on_the_way_status_hour &&
                appointment.service_completed_status_hour
                ? calculateDistanceBetweenHours(
                  appointment.on_the_way_status_hour,
                  appointment.service_completed_status_hour
                ).toString()
                : "sem registro",
            start_time: appointment.hour || "sem registro",
            end_time:
              appointment.service_completed_status_hour || "sem registro",
            real_start_time: appointment.on_the_way_status_hour || "",
            real_end_time: appointment.service_completed_status_hour || "",
            customer_name: appointment.customer.user.name || "sem registro",
            customer_cpf: appointment.customer.user.cpf || "sem registro",
            pet_name: appointment.pet.name || "sem registro",
            pet_breed: appointment.pet.breed?.name || "sem registro",
            pet_gender: appointment.pet.gender || "sem registro",
            pet_specie: appointment.pet.specie.name || "sem registro",
            pet_size: appointment.pet?.breed?.size
              ? appointment.pet?.breed?.size
              : calculateSizeByWeigthPet(
                appointment.pet.specie.name,
                appointment.pet.weight || 0
              ) || "sem registro",
            cep: appointment.address.cep,
            address_street: appointment.address.street || "sem registro",
            address_neighborhood:
              appointment.address.neighborhood || "sem registro",
            address_number: appointment.address.number || "sem registro",
            service_name: appointment.service.name || "sem registro",
            service_full_price: convertToCurrencyFormat(serviceTotalPrice),
            additionals:
              appointment.appointments_items
                .map((additional) => additional.item.name)
                .toString() || "sem registro",
            additionals_full_price: convertToCurrencyFormat(
              additionalsTotalPrice.toFixed(2)
            ),
            discount: convertToCurrencyFormat(appointment.order.total_discount),
            coupon_relation:
              appointment.order.coupon_relation?.code || "Sem cupom",
            paided_price: convertToCurrencyFormat(parseFloat(appointmentPaidPrice).toFixed(2)),
            payment: payment,
            observations_admin: "",
            power_supply: appointment.power_supply
              ? PowerSupplyEnum[appointment.power_supply]
              : "sem registro",
            professional: {
              id: appointment.professional?.id,
              name: appointment.professional?.user.name,
            },
            platform: platform,
          };
        })
      );
    } catch (error) {
      setPage(new_page - 1);
      const { message } = processError(error, "GRAPHQL");
      launchToast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const AppointmentFiltersModalControl = useDisclosure();

  return (
    <>
      {loading && <Loading />}

      <Styles.BackgroundPanel>
        <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Relatórios"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>

          <Styles.WhiteButton onClick={AppointmentFiltersModalControl.onOpen}>
            Buscar
          </Styles.WhiteButton>
        </Styles.PanelHeader>

        <Styles.TablePanel>
          <Styles.FilterDisplay>
            <div>
              <Text
                text="Agendamentos"
                color={colors.argon.textColorDark}
                fontFamily="Open Sans"
                size={17}
                weight="600"
                marginRight={4}
                marginTop={0}
                align="left"
              />
            </div>

            <Styles.TabHeaderRow>
              <Styles.Option
                onClick={() => setSelectedTabOption("table")}
                selected={selectedTabOption === "table"}
              >
                Tabela
              </Styles.Option>

              <Styles.Option
                onClick={() => setSelectedTabOption("totals")}
                selected={selectedTabOption === "totals"}
              >
                Totais
              </Styles.Option>
            </Styles.TabHeaderRow>
          </Styles.FilterDisplay>

          {selectedTabOption === "table" && (
            <Styles.Content>
              <Styles.ContentHeaderDateInfo>
                <Text
                  text={
                    selectedFilters
                      ? `Listagem do dia ${format(
                        addDays(new Date(selectedFilters.start_date), 1),
                        "dd/MM/yyyy"
                      )} até ${format(
                        addDays(new Date(selectedFilters.end_date), 1),
                        "dd/MM/yyyy"
                      )}`
                      : "Selecione as datas no botão (Buscar) para listar os agendamentos"
                  }
                  color={colors.gray.dark01}
                  fontFamily="Open Sans"
                  size={14}
                  align="left"
                  weight="400"
                />
              </Styles.ContentHeaderDateInfo>

              {appointmentsFormatted && (appointmentsFormatted?.length > 0
                ? (
                  <>
                    <Styles.ContentTable>
                      <AppointmentsReportTable
                        data={appointmentsFormatted}
                      />
                    </Styles.ContentTable>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: "16px",
                      }}
                    >
                      <Styles.ButtonExport
                        onClick={() => handleExportFullData()}
                        disabled={exportingExcel}
                      >
                        Exportar dados
                      </Styles.ButtonExport>
                      {exportingExcel && <p>exportando...</p>}
                      <Pagination
                        totalPage={numberOfPages}
                        canPreviousPage={page > 1}
                        previousPage={() => {
                          page > 1 && onPageChange(page - 1)
                        }}
                        nextPage={() => {
                          page < numberOfPages && onPageChange(page + 1)
                        }}
                        canNextPage={page < numberOfPages}
                        pageIndex={page}
                        setPage={onPageChange}
                      />
                    </div>
                  </>
                ) : (
                  <Text
                    text="Sua busca não retornou nenhum resultado"
                    color={colors.gray.dark01}
                    fontFamily="Open Sans"
                    size={16}
                    align="left"
                    weight="400"
                  />
                ))}
            </Styles.Content>
          )}
          {selectedTabOption === "totals" && (
            <Styles.Content>
              {!!totalResumePrices ? (
                <AppointmentsResumePrices data={totalResumePrices} />
              ) : (
                <Styles.ContentHeaderDateInfo>
                  <Text
                    text={
                      selectedFilters
                        ? `Listagem do dia ${format(
                          addDays(new Date(selectedFilters.start_date), 1),
                          "dd/MM/yyyy"
                        )} até ${format(
                          addDays(new Date(selectedFilters.end_date), 1),
                          "dd/MM/yyyy"
                        )}`
                        : "Selecione as datas no botão (Buscar) para listar os agendamentos"
                    }
                    color={colors.gray.dark01}
                    fontFamily="Open Sans"
                    size={14}
                    align="left"
                    weight="400"
                  />
                </Styles.ContentHeaderDateInfo>
              )}
            </Styles.Content>
          )}
        </Styles.TablePanel>
      </Styles.BackgroundPanel>

      <AppointmentFiltersModal
        onClose={AppointmentFiltersModalControl.onClose}
        isOpen={AppointmentFiltersModalControl.isOpen}
        onSubmit={handleSearchAppointmentsByDateRange}
      />
    </>
  );
};

export default AppointmentsReport;
