import { Divider, Heading } from "@chakra-ui/react";
import { Form } from "@unform/web";
import { Label } from "components/Label/styles";
import Modal from "components/Modal";
import Text from "components/Text";
import TextInput from "components/TextInput";
import { format } from "date-fns";
import React from 'react';
import { normalizePlatformEnum } from "shared/enums/PlatformEnum";
import convertToCurrencyFormat from "shared/utils/convertToCurrencyFormat";
import { BrazilianPaymentType } from "shared/utils/enums/TypePaymentEnum";
import { Row } from "../OrdersErrors/styles";
import { IOrderErrorLog } from "../OrdersErrors/types";
import OrderServiceCard from "./OrderServiceCard";

export type IOrderErrorDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orderError: IOrderErrorLog;
};

export default function OrderErrorDetailsModal({
  isOpen,
  onClose,
  orderError
}: IOrderErrorDetailsModalProps) {
  return (
    <Modal closeModal={onClose} visible={isOpen}>
      <Heading>Detalhes do pedido</Heading>
      <Form onSubmit={()=> null}>
        <Row>
          <TextInput
            label="Valor da compra"
            name="orderValue"
            value={convertToCurrencyFormat(orderError.order_value) || "Não identificado"}
            disabled
          />
          <TextInput
            label="Doação"
            name="donated"
            value={convertToCurrencyFormat(orderError.dto.donated || 0)}
            disabled
          />
          <TextInput
            label="Alteração de admin (valor)"
            name="adminChangeAmount"
            value={String(orderError.dto.adminChange?.amount || "Sem alteração")}
            disabled
          />
          <TextInput
            label="Alteração de admin (tipo)"
            name="adminChangeType"
            value={orderError.dto.adminChange?.type || "Sem alteração"}
            disabled
          />
        </Row>
        <Row>
          {/* <TextInput
            label="Habilitado pra comprar domingo"
            name="canCreateOnSunday"
            value={orderError.dto.canCreateOnSunday ? "Sim" : "Não"}
            disabled
          /> */}
          <TextInput
            label="Plataforma"
            name="platform"
            value={normalizePlatformEnum(orderError.dto.platform)}
            disabled
          />
          <TextInput
            label="Tipo de pagamento"
            name="paymentMethodType"
            value={BrazilianPaymentType[orderError.dto.paymentMethod.type] || "Não identificado"}
            disabled
          />
          <TextInput
            label="Numero do cartão"
            name="cardNumber"
            value={orderError.dto.paymentMethod.cardId || "Sem cartão"} // aplicar mascara
            disabled
          />
          <TextInput
            label="Saldo da carteira"
            name="walletUseIt"
            value={orderError.dto.wallet.useIt ? "Sim" : "Não"}
            disabled
          />
        </Row>
        <Divider />
        <Heading size="lg" textAlign="center" marginTop={8}>Carrinho</Heading>
        {!!orderError.dto.cart.packs.length && orderError.dto.cart.packs.map((pack) => (
        <>
          <Heading size="lg" marginTop={8}>Pacote</Heading>
          <Row>
            <TextInput
              label="Id"
              name="id"
              value={pack.id}
              disabled
            />
            <TextInput
              label="Renovação automática"
              name="autoRenew"
              value={pack.autoRenew ? "Sim" : "Não"}
              disabled
            />
            <TextInput
              label="Frequência"
              name="frequency"
              value={orderError.dto.wallet.useIt ? "Sim" : "Não"}
              disabled
            />
          </Row>
          {pack.services.map((service) => (
            <OrderServiceCard
              service={service}
              key={service.id}
            />
          ))}
        </>
      ))}
      {orderError.dto.cart.services.map((service) => (
        <OrderServiceCard
          service={service}
          key={service.id}
        />
      ))}
      {!!orderError.dto.cart.products.productList.length && (
        <>
          <Label>Endereço de entrega:
            <Text text={orderError.dto.cart.products.addressId} />
          </Label>
          {orderError.dto.cart.products.productList.map((product) => (
            <>
              <Label>Id:
                <Text text={String(product.id)} />
              </Label>
              <Label>Quantidade:
                <Text text={String(product.quantity)} />
              </Label>
            </>
          ))}
        </>
      )}
      </Form>
    </Modal>
  );
}
